import React from "react"
import { useFormContext } from "react-hook-form"
import InputBox from "./InputBox"

const TextAreaInput = (props) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  const { name, placeholder, label, boxClassName } = props
  return (
    <InputBox
      name={name}
      label={label}
      errors={errors}
      className={boxClassName}
    >
      <textarea
        id={name}
        className="input-field input-textarea"
        placeholder={placeholder}
        {...props}
        {...register(name)}
      />
    </InputBox>
  )
}

export default TextAreaInput
